// @import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
// @import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import 'variables';

$font-family-base: Roboto;
$primary: $primary-blue;
$secondary: $secondary-blue;
$light: $light-aviada;

.text-gray{
  color: $gray-aviada !important;
  font-family: Roboto, sans-serif;
}

.text-light-aviada{
  color: $light-aviada !important;
}

.text-light-blue-1{
  color: $light-blue-02 !important;
}

.text-light-blue-2{
  color: $light-blue-02 !important;
}

.bg-light-01{
  background-color: $light-aviada-01;
}

.bg-dark-blue {
  background-color: $dark-blue-03;
}

.btn-close{    
  filter: invert(31%) sepia(97%) saturate(959%) hue-rotate(173deg) brightness(90%) contrast(105%);
}

$font-size-base:              1.1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .75 !default;
$font-size-xs:                $font-size-base * .5 !default;
$font-size-lg:                $font-size-base * 1.5 !default;

$h1-font-size:                $font-size-base * 4 !default;
$h2-font-size:                $font-size-base * 3 !default;
$h3-font-size:                $font-size-base * 2.5 !default;
$h4-font-size:                $font-size-base * 2 !default;
$h5-font-size:                $font-size-base * 1.5 !default;
$h6-font-size:                $font-size-base * 1.1 !default;
$h7-font-size:                $font-size-base !default;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
  8: $font-size-sm,
  9: $font-size-xs,
);

$spacer: 1rem; 
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5.5,
  quote: $spacer * 17,
  mquote: $spacer * 46,
);

// scss-docs-start border-variables
$border-width: 1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  l: 1rem,
  xl: 2rem,
  xxl: 3rem,
) !default;

.card-text{
  @extend .text-secondary;
}

h1, h2, h3 {
  @extend .mt-0;
  @extend .text-primary;
  &+div, &+p {
    @extend .fs-6;
  }
}


h1 {
  @extend .mb-4;
  b {
    @extend .fst-italic;
    @extend .text-secondary;
    font-family: system-ui;
  }
}

h2, h3 {
  @extend .mb-2;
  b {
    @extend .d-block;
    @extend .fst-italic;
    @extend .fs-5;
    @extend .text-secondary;
  }
}

section {
  @extend .p-4;
  @extend .p-md-5;
  & > .container {
    @extend .my-5;
  }
}

.top-decoration {
  &:before {
    border: thick solid $primary-blue;
    content: "";
    width: 100px;
    position: relative;
    margin-bottom: 1rem;
    display: block;
  }
  strong {
    font-style: italic;
  }
  p {
    @extend .m-0;
  }
}

.bottom-decoration {
  &:after {
    border: thick solid $primary-blue;
    content: "";
    width: 100px;
    position: relative;
    margin-top: 1rem;
    display: block;
  }
  strong {
    font-style: italic;
  }
  p {
    @extend .m-0;
  }
}

.container-fluid {
  @extend .p-0;
}

main .container-fluid:nth-child(n+2) {
  @extend .py-5;
}

.vh-50 {
  height: 50vh !important;
}

.vh-70 {
  height: 75vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-50 {
  height: 35vh !important;
}

.vh-40 {
  height: 35vh !important;
}

.vh-35 {
  height: 35vh !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-30 {
  height: 30vh !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-90{
  width: 90% !important;
}

.h-30 {
  height: 30% !important;
}

.h-70{
  height: 70% !important;
}

.opacity-10 {
  opacity: .10 !important;

}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-30 {
  opacity: .30 !important;
}

.opacity-85 {
  opacity: .85 !important;
}
 
li.with-bullet::before {
  content: "•";
  margin-left: -1.3rem;
  @extend .me-3; 
  @extend .text-primary;
}

li.no-bullet::before {
  content: unset;
}

.rounded-sm {
  border-radius: .7rem !important;
}

.rounded-md {
  border-radius: 1rem !important;
}

.rounded-lg {
  border-radius: 1.2rem !important;
}

.rounded-xl {
  border-radius: 1.5rem !important;
}

.info-box {
  box-shadow: 10px 10px 0 0 $primary-blue;
}

.btn {
  @extend .rounded-0;
  &:hover {
    scale: 103%;
    transition: all .3s;
  }
  scale: 100%;
  transition: all .3s;
}