body {
    &.our-team {
        #our-team {
            background-color: $primary-blue !important;
            transition: background-color .5s;
            .text-color-change {
                color: #FFFFFF !important;
            }
        }
    }
    #our-team {
        transition: background-color .5s;
    }
}

.team-card {
    cursor: initial;
    background-color: transparent;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    .image-holder {
        min-height: 292px;
        min-width: 292px;
        aspect-ratio: 1 / 1;
        position: relative;
        text-align: center;
        transition: transform .3s;
        transform-style: preserve-3d;
        background-color: #FFFFFF;
        img {
            transition: all .2s;
            filter: grayscale(.75);
            position: absolute;
            width: 100%;
            aspect-ratio: 1 / 1;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
            &.team-picture {
                background-color: #bbb;
                color: black;
            }
            &.back-picture {
                background-color: dodgerblue;
                color: white;
                transform: rotateY(180deg);
            }
        }
    }
    &:hover {
        transform: scale(1.08);
        transition: all .2s;
        img {
            transition: all .12s;
            filter: none;
        }
    }
    &>div {
       transition: all .2s; 
    }
}
  
// .team-card:hover .image-holder {
//     transform: rotateY(180deg);
// }