nav {
  background-color: transparent;
  transition: all .2s ease-in;

  .navbar-brand img{
    filter: brightness(0) invert(1);
  }

  a { 
    @extend .text-gray;
    @extend .text-decoration-none;
    font-size: $h6-font-size !important;
  }
  .navbar-nav {
    li::before {
      content: unset;
    }
  }
  #navbar-nav{
    a {
      @extend .text-white;
      @extend .mx-2;
      @extend .mx-md-3;
    }
    .highlighted-item-menu {
      a {
        color: $primary-blue !important;
      }
    }
    .current_page_item {
      a {
        @extend .text-decoration-underline;
        text-underline-offset:1.4rem;
      }
    }
  }

  &.main-nav-scrolled {
    background-color: #ffffff;
    transition: all .2s ease-in;
    box-shadow:  0 0 1rem $gray-aviada;

    .navbar-brand img {
      filter: none;
    }

    #navbar-nav {
      a {
        @extend .text-secondary;
      }
      &.show, &.collapsing {
        .current_page_item {
          a {
            @extend .text-decoration-none;
          }
        }
      }
    }
  }

  &.main-nav:not(.main-nav-scrolled) {
    .navbar-collapse#navbar-nav {
      &.show, &.collapsing {
        background-color: rgba(0,0,0,.4);
        transition: background-color .2s;
        .current_page_item {
          a {
            @extend .text-decoration-none;
          }
        }
      }
    }
  }

  &.main-nav {
    .navbar-collapse {
      .menu-item {
        @extend .my-2;
      }
    }
  }

  #navbar-sub{
    a {
      @extend .text-white;
    } 
    .menu-item{
      @extend .py-3;
      @extend .me-4;
    }   
    .menu-item:nth-child(6) {
      background-color: $primary;
      @extend .px-3;  
    } 
    @include media-breakpoint-down(md){
      .mobile-menu{
        display: none;
      } 
      .menu-item{
        margin: .5rem !important;
        padding: 0 .5rem .5rem .5rem !important;
        a {
          font-size: $h6-font-size !important;
        }
      }
    }
  }
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(16, 103, 168, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.home .home-link {
  @extend .d-none;
}