.portfolio-item {
    cursor: pointer;
    transform: scale(1);
    transition: all .2s;
    img {
        transition: all .2s;
        filter: grayscale(.75);
    }
    &:hover {
        transform: scale(1.08);
        transition: all .2s;
        img {
            transition: all .2s;
            filter: none;
        }
    }
}
