#hero {
  .container {
    @extend .my-0;
  }
  @extend .container-fluid;
  @extend .position-relative;
  @extend .p-0;
  .floating-box {
    @extend .position-absolute;
    @extend .w-100;
    @extend .p-5;
    @extend .bg-white;
    bottom: -3.5rem;
    min-height: 160px;
  }
  @include media-breakpoint-up(md) {
    .floating-box {
      left: 10%;
      width: 80% !important;
    }
  }
  @include media-breakpoint-up(lg) {
    .floating-box {
      left: 40%;
      width: 50% !important;
    }
  }
  @include media-breakpoint-up(xxl) {
    .floating-box {
      left: 55%;
      width: 35% !important;
    }
  }
}