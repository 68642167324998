footer {
  li {
    @extend .text-start;
    @extend .ms-5;
    @extend .py-1;
    &::before {
      content: unset;
    }
    a {
      @extend .text-white;
      @extend .text-decoration-none;
      &:hover {
        @extend .fw-bold;   
      }
    }
  }
}

#to-vc {
  &.element-glow {
    animation: glow .6s infinite alternate;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px $secondary-blue;
  }
  to {
    box-shadow: 0 0 20px -5px $primary-blue;
  }
}