.recent-blog-post .card {
  min-height: 150px;
  border-radius: unset;
}

@include media-breakpoint-only(md) { 
  .recent-blog-post .card {
    height:305px;
  }
}

@include media-breakpoint-up(lg) { 
  .recent-blog-post .card {
    height: 200px;
  }
}

.single #content {
  h2, h3 {
    @extend .mt-5;
    @extend .mb-3;
  }
  h1 {
    @extend .fs-3;
  }
  h2 {
    @extend .fs-4;
  }
  h3 {
    @extend .fs-5;
  }
}