.step-block, 
.arrow-box {
  .step{
    opacity: 0;
    transition: opacity 500ms;
    &:last-child {
      @extend .mb-0;
    }
  }
  .step.scrolled{
    opacity: 1;
    animation: slide-in-left 1s ease-in-out both;
  }
  .arrow{
    transition: opacity 500ms;
    right: 200%;
    mask-size: 0%;
    mask-repeat: no-repeat;  
    mask-mode: alpha;
    height: 90%;
  }
  .arrow.move {
    animation: arrow 5s ease forwards;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
