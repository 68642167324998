.testimonial-card {
    height: 20rem;
    blockquote {
        @extend .fs-7;
        @extend .border-start;
        @extend .border-4;
        @extend .ps-3;
        @extend .text-start;
        @extend .fst-italic;
    }
}