.text-list-button {
    .list-item-container {
        cursor: pointer;
        &:hover {
            @extend .bg-light;
            @extend .fw-bold;
            transition: all .3s;
        }
        transition: all .3s;
        @extend .fw-normal;
    }   
}