//Colors
$primary-blue: #0072B5;
$secondary-blue: #1B3156;
$light-aviada: #EFEFEF;
$light-aviada-01: #EDEDED;
$light-blue: #CEDDE4;
$light-blue-01: #ADCDDD;
$light-blue-02: #9FC5DB;
$light-blue-03: #B9D3E2;
$bright-blue: #1067A8;
$dark-blue: #183153;
$dark-blue-01: #193153;
$dark-blue-02: #162B4B;
$dark-blue-03: #1B2B4A;
$gray-aviada: #707070;