@import "bootstrap.scss";
@import 'partials/blog';
@import 'partials/nav';
@import 'partials/forms';
@import 'partials/footer';
@import 'partials/fulltext';
@import 'partials/hero';
@import 'partials/logos';
@import 'partials/our-team';
@import 'partials/testimonials';
@import 'partials/openings';
@import 'partials/portfolio-items';
@import 'partials/positions';
@import 'partials/side-picture';
@import 'partials/steps';
@import 'partials/text-list';

.separator {
  @extend .border-top;
  @extend .border-xl;
  @extend .mb-4;
  @extend .w-25;
  @extend .border-secondary;
}

.star-list{
  li:before {
    content: "\002A"; 
    @extend .text-warning;
    padding-right: 1rem;
    width: 1.3em; 
  }
}

#wcb_2 {
  color: #FFFFFF;
}

.menu-anchor{
  scroll-margin: 25vh !important;
}

.page-404 {
  main{
    background-color: $light;
  }
}

@include media-breakpoint-up(md) {
  .w-md-75 {
    width: 75% !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }
}
